import { mainSplider, thumbnailSplider } from '../carousel';

let holderIndex;
let animation;

function initTextImageIconCarousel() {
	const events = () => {
		mainSplider.on('move', (index) => {
			const thumbnailContainer =
				thumbnailSplider._Components.Elements.list.children;

			const activeThumbnail = thumbnailContainer[index];
			let distance = index - holderIndex;
			if (distance < 0) distance *= -1;

			if (holderIndex > index) {
				animation('left', activeThumbnail, distance);
			} else {
				animation('right', activeThumbnail, distance);
			}

			holderIndex = index;
		});
	};

	events();
}

document.addEventListener('DOMContentLoaded', () => {
	const iconCarousel = document.querySelectorAll(
		'.timeline'
	);

	if (!iconCarousel.length) return;

	initTextImageIconCarousel();
});
