// Bootstrap Imports

import Collapse from 'bootstrap/js/dist/collapse';
import Dropdown from 'bootstrap/js/dist/dropdown';
import ScrollBarHelper from 'bootstrap/js/src/util/scrollbar';
import 'bootstrap/js/dist/modal';

/*******************************************
 *  Helper Functions
 *******************************************/

// Handles flash of unstyled content.
function fouc() {
	const html = document.querySelector( 'html' );
	html.classList.remove( 'no-js' );
	html.classList.add( 'js' );
}

// Optimize the scroll events for better browser performance.
//
// Broadcasts optimizedScroll event on the window.
// Use the same way you would add a window scroll listener.
// window.on('optimizedScroll', ...);
function optimizeScroll() {
	let scrollWaiting = false,
		endScrollHandle;
	window.addEventListener( 'scroll', () => {
		if ( scrollWaiting ) {
			return;
		}
		scrollWaiting = true;

		// clear previous scheduled endScrollHandle
		clearTimeout( endScrollHandle );

		window.dispatchEvent( new Event( 'optimizedScroll' ) );

		setTimeout( function () {
			scrollWaiting = false;
		}, 100 );

		// schedule an extra execution of scroll() after 200ms
		// in case the scrolling stops in next 100ms
		endScrollHandle = setTimeout( function () {
			window.dispatchEvent( new Event( 'optimizedScroll' ) );
		}, 200 );
	} );
}

function mainNavigationScroll() {
	window.addEventListener( 'optimizedScroll', () => {
		const scrollY = window.scrollY;
		const masthead = document.getElementById( 'masthead' );

		if ( scrollY > 68 ) {
			masthead.classList.remove( 'top' );
		} else {
			masthead.classList.add( 'top' );
		}
	} );
}

function mainNavigation() {
	mainNavigationScroll();

	const navCollapseEl = document.getElementById( 'main-menu-collapse' );
	const masthead = document.getElementById( 'masthead' );
	const siteNav = document.getElementById( 'site-navigation' );
	const scrollBar = new ScrollBarHelper();
	const navbar = document.querySelector( '.navbar-nav' );
	const secondMenu = document.getElementById( 'secondary-menu' );

	// Use Popper on navbar dropdowns
	Dropdown.prototype._detectNavbar = function () {
		// TODO only allow popper on larger devices.
		// Needs to support changing device sizes.
		return false;
	};

	// Add and remove class name open to navbar and secondary menu
	navbar.querySelectorAll( ".dropdown" ).forEach((el) => {
		el.addEventListener( 'show.bs.dropdown', ()=>{
			navbar.classList.add( 'open' );
			secondMenu.classList.add( 'open' );
		})
	})
	navbar.querySelectorAll( ".dropdown" ).forEach((el) => {
		el.addEventListener( 'hide.bs.dropdown', ()=>{
			navbar.classList.remove( 'open' );
			secondMenu.classList.remove( 'open' );
		})
	})

	// Solution to allow nested dropdowns with BSv5
	// https://jsfiddle.net/dallaslu/mvk4uhzL/
	const SHOW_CLASS_NAME = 'has-child-dropdown-show';
	Dropdown.prototype.toggle = ( function ( _orginal ) {
		return function () {
			document
				.querySelectorAll( '.' + SHOW_CLASS_NAME )
				.forEach( function ( e ) {
					e.classList.remove( SHOW_CLASS_NAME );
				} );
			let dd = this._element
				.closest( '.dropdown' )
				.parentNode.closest( '.dropdown' );
			for (
				;
				dd && dd !== document;
				dd = dd.parentNode.closest( '.dropdown' )
			) {
				dd.classList.add( SHOW_CLASS_NAME );
			}
			return _orginal.call( this );
		};
	} )( Dropdown.prototype.toggle );

	document.querySelectorAll( '.dropdown' ).forEach( function ( dd ) {
		dd.addEventListener( 'hide.bs.dropdown', function ( e ) {
			if ( this.classList.contains( SHOW_CLASS_NAME ) ) {
				this.classList.remove( SHOW_CLASS_NAME );
				e.preventDefault();
			}
			e.stopPropagation(); // do not need pop in multi level mode
		} );
	} );

	// Opt-in enable hover toggle on dropdowns
	// Add .dropdown-hover to the .dropdown, or add .dropdown-hover-all to a parent element.
	//
	// Note: it is generally a better idea to enable dropdowns on click.
	document
		.querySelectorAll( '.dropdown-hover, .dropdown-hover-all .dropdown' )
		.forEach( function ( dd ) {
			dd.addEventListener( 'mouseenter', function ( e ) {
				const toggle = e.target.querySelector(
					':scope>[data-bs-toggle="dropdown"]'
				);
				if ( ! toggle.classList.contains( 'show' ) ) {
					Dropdown.getOrCreateInstance( toggle ).toggle();
					dd.classList.add( SHOW_CLASS_NAME );
					Dropdown.clearMenus();
				}
			} );
			dd.addEventListener( 'mouseleave', function ( e ) {
				const toggle = e.target.querySelector(
					':scope>[data-bs-toggle="dropdown"]'
				);
				if ( toggle.classList.contains( 'show' ) ) {
					Dropdown.getOrCreateInstance( toggle ).toggle();
				}
			} );
			// Redirect to link if it exists/is valid.
			dd.addEventListener( 'click', function () {
				const toggle = this.querySelector(
					':scope>[data-bs-toggle="dropdown"]'
				);
				if (
					! toggle.getAttribute( 'href' ).lastIndexOf( '#', 0 ) !== 0
				) {
					window.location.href = toggle.getAttribute( 'href' );
				}
			} );
		} );

	// Prevent scroll while open
	navCollapseEl.addEventListener( 'show.bs.collapse', function () {
		masthead.style.paddingRight = `${ scrollBar.getWidth() }px`;
		scrollBar.hide();
	} );
	navCollapseEl.addEventListener( 'hide.bs.collapse', function () {
		masthead.style.paddingRight = '';
		scrollBar.reset();
	} );

	// Close main navigation on resize
	window.addEventListener( 'resize', function () {
		if (
			typeof navCollapseEl !== 'undefined' &&
			navCollapseEl.classList.contains( 'show' )
		) {
			const navCollapse = Collapse.getInstance( navCollapseEl );
			navCollapse.hide();
		}
		if (window.innerWidth > 992) navCollapseEl.classList.add( 'collapse-horizontal' );
		if (window.innerWidth < 991) navCollapseEl.classList.remove( 'collapse-horizontal' );
	} );

	// Remove collapse-horizontal on mobile
	if( window.matchMedia("(max-width: 991px)").matches ) {
		navCollapseEl.classList.remove( 'collapse-horizontal' );
	}

	// Add class name on navbar main navigation
	navCollapseEl.addEventListener( 'show.bs.collapse', function () {
		siteNav.classList.add( 'open' );
	} );
	navCollapseEl.addEventListener( 'hide.bs.collapse', function () {
		siteNav.classList.remove( 'open' );
	} );
}

/**
 * Popup Maker popups prevent body scroll on popups with an overlay.
 * To prevent layout shifting due to the disappearing scrollbar, an
 * offset is added via padding-right. However, the value from the plugin
 * is static and does not work on all browsers. This function calculates
 * the scrollbar size (if it exists) on each open event and applies the
 * required offset.
 */
function popupMakerOverlayFix() {
	if (typeof jQuery === undefined) { return }
	const pum = document.getElementsByClassName('pum');
	if (pum.length > 0) {
		const $page = jQuery('#page');
		const $adminbar = jQuery('#wpadminbar');
		const getScrollbarWidth = () => {
			const documentWidth = document.documentElement.clientWidth;
			return Math.abs(window.innerWidth - documentWidth);
		};
		const overlayDisabled = () => {
			const $popup = PUM.getPopup(this); // eslint-disable-line no-undef
			const settings = $popup.popmake('getSettings');
			return (
				'overlay_disabled' in settings && settings.overlay_disabled === true
			);
		};

		jQuery(document)
			.on('pumBeforeOpen', () => {
				if (overlayDisabled()) return;
				const width = getScrollbarWidth();
				$page.css('padding-right', `${width}px`);
				$adminbar.css('padding-right', `${width}px`);
			})
			.on('pumAfterClose', () => {
				if (overlayDisabled()) return;
				$page.css('padding-right', '');
				$adminbar.css('padding-right', '');
			});
	}
}

/*******************************************
 *  Custom Functions for the theme
 *******************************************/

/**
 * On Loads
 */
document.addEventListener( 'DOMContentLoaded', () => {
	fouc();
	optimizeScroll();
	mainNavigation();
	mainNavigationScroll();
	popupMakerOverlayFix();
} );
