
const tabButtons = [...document.getElementsByClassName("nav-link")]
const tabItems = [...document.getElementsByClassName("tab-pane")]


tabButtons.forEach((element)=>{
	element.addEventListener("click", () => {
		changeTab(element);
	})
})

const toggleButton  = (element, parentSectionId) =>{
	tabButtons.forEach((button)=>{
		if(!button.classList) return
		if(button.getAttribute("parent-section") === parentSectionId){
			button.classList.remove("active");
		}
	})
	element.classList.add("active")
}
const toggleItems = (target, parentSectionId) =>{
	if(!target) return
	tabItems.forEach((tab)=>{
		if(tab.getAttribute("parent-section") === parentSectionId){
			tab.classList.remove("show");
		}
	})
	target.classList.add("show");
}
const getTarget = targetID => tabItems.find((tab) => tab.getAttribute("id") === targetID)

const changeTab = (element) =>{
	const parentSectionId = element.getAttribute("parent-section");
	const target = getTarget(element.getAttribute("target"));
	toggleButton(element, parentSectionId);
	toggleItems(target, parentSectionId);
}
